import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react"
import axios from "axios"
import { useLayoutEffect, useState } from 'react'

const UserContext = React.createContext();

function UserContextProvider({ children }) {
    const [authorizedProducts, setAuthorizedProducts] = useState([]);
    const [accessToken, setAccessToken] = useState("");
    const [subscriptionDataFetched, setSubscriptionDataFetched] = useState(false);
    const [newsletterPopupShown, setNewsletterPopupShown] = useState(false);
    const { getAccessTokenSilently, user, isLoading: loadingAuthData, isAuthenticated } = useAuth0();

    useLayoutEffect(() => {
        const getUserProducts = async () => {
            if (process.env.DEBUG_MODE) {
                console.log("Fetching authorized product info from Auth0");
            }
            const access_token = await getAccessTokenSilently();
            setAccessToken(access_token);
            await axios
              .request({
                  url: "/api/get-authorized-products",
                  method: "GET",
                  headers: {
                      authorization: `Bearer ${access_token}`,
                  },
                  params: { userId: user.sub },
              })
              .then(response => {
                  if (process.env.DEBUG_MODE) {
                      console.log("Authorized products:", response?.data);
                  }
                  setAuthorizedProducts(response.data);
              })
              .catch(err => {
                  if (process.env.DEBUG_MODE) {
                      console.log("Fetch data from '/api/get-authorized-products' failed!");
                  }
                  console.log(err.message);
              });
            setSubscriptionDataFetched(true);
        };
        if (user) {
            getUserProducts();
        }
    }, [user]);

    const value = {
        user,
        isAuthenticated,
        authorizedProducts,
        accessToken,
        loadingAuthData,
        subscriptionDataFetched,
        newsletterPopupShown,
        setNewsletterPopupShown
    };
    return (
      <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
}

function useUserData() {
    const context = React.useContext(UserContext);
    // if (window !== undefined && context === undefined) {
    //     throw new Error("useUserData must be used within a UserContextProvider");
    // }
    return context;
}

export { UserContextProvider, useUserData };
